export const getPhoneLink = () => 'tel:88003013309'
export const getPhoneText = () => '8 (800) 301-33-09'
export const getEmailLink = () => 'mailto:pr@pedant.ru'
export const getEmailText = () => 'pr@pedant.ru'
export const getTelegramLink = (version: number = 1): string => {
  const bots = ['PedantMarket_bot', 'PedantMarket2_bot']

  if (version < 1 || version > bots.length) return `https://t.me/${bots[0]}`

  return `https://t.me/${bots[version - 1]}`
}
export const getVkLink = () => 'https://vk.me/pedant.market?ref=pm_vkbot_test'
export const getWhatsappLink = (text: string = 'Здравствуйте! У меня вопрос') =>
  `https://api.whatsapp.com/send/?phone=79279260969&text=${text}`
export const getRefundPageLink = () => 'https://lk.pedant.market/refund'